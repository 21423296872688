import { memo } from 'react'

import { ReactComponent as UfoSvg } from '../../assets/images/image/image_ufo.svg'
import Footer from '../../components/Footer/Footer'
import { DashboardWrapper, Wrapper } from '../common.styles'
import { Container, Description, Header, LeftSide, RightSide } from './Error404.dashboard.styled'

type ErrorDashboardFn = () => JSX.Element

const Error404Dashboard: ErrorDashboardFn = (): JSX.Element => {
  return (
    <>
      <DashboardWrapper>
        <Wrapper>
          <Container>
            <LeftSide>
              <UfoSvg />
            </LeftSide>
            <RightSide>
              <Header>404</Header>
              <Description>Valitettavasti etsimääsi lähetystä tai sivua ei ole olemassa.</Description>
            </RightSide>
          </Container>
        </Wrapper>
      </DashboardWrapper>
      <Footer />
    </>
  )
}

export default memo(Error404Dashboard)
