import styled from 'styled-components'

import { breakpoints } from '../../../../theme/breakpoints'

export const WrapperLessThen480 = styled.div`
  text-align: center;
`

export const CodeWrapper = styled.div`
  padding-left: 16px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PinCodeText = styled.div`
  color: var(--font-black);
  font-family: OpenSans-Bold, sans-serif;
  font-size: 38px;
  line-height: 48px;
  letter-spacing: 1px;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    text-align: unset;
  }
`

export const Text = styled.div`
  margin-bottom: 24px;
  color: var(--font-black);
  font-family: OpenSans-Bold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    text-align: unset;
    margin: 0;
  }
`

export const SVGWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    box-sizing: content-box;
    border: 1px solid #dfdbed;
    border-radius: 16px;
    background-color: var(--white);
    padding: 12px;
  }
`

export const SVGLogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
`
