import styled from 'styled-components'

import { breakpoints } from '../../theme/breakpoints'

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CommercialImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.sm}) {
    div {
      flex-basis: calc(50% - 16px);
    }
  }
`
