export const breakpoints = {
  lg: '840px',
  md: '600px',
  sm: '480px',
  xl: '1080px',
  xl1: '1440px',
  xl2: '1920px',
}

export const mediaQueries = (key: keyof typeof breakpoints) => {
  return (style: TemplateStringsArray | string) => `@media screen and (min-width: ${breakpoints[key]}em) { ${style} }`
}
