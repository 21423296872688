import { Box } from '@mui/material'
import { memo } from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'

import { useTranslation } from '../../../../hooks/useTranslation'
import { useAppState } from '../../../../overmind'
import { getCompanyLogo } from '../../../../utils/helpers.icons'
import { CodeWrapper, PinCodeText, SVGWrapper, Text, WrapperMoreThen480 } from './QR.size480.styled'

const QRSize480 = (): JSX.Element => {
  const { translate } = useTranslation()
  const { pickupCode } = translate('qrCode')

  const { item } = useAppState().messages

  const codeLong = item?.lock?.codeLong
  const codeShort = item?.lock?.codeShort

  const clientObject = getCompanyLogo({ customerId: item?.customer?.customerId })

  return (
    <WrapperMoreThen480>
      <SVGWrapper>
        <QRCode level="Q" size={160} value={codeLong} />
      </SVGWrapper>

      <CodeWrapper>
        <StyledLogo imgPosition={clientObject?.position || undefined} sx={{ padding: '16px 0 0', textAlign: 'center' }}>
          {clientObject?.img || <></>}
        </StyledLogo>

        <div>
          <PinCodeText>{codeShort}</PinCodeText>
          <Text>{pickupCode}</Text>
        </div>
      </CodeWrapper>
    </WrapperMoreThen480>
  )
}

// <SVGLogoWrapper>{getCompanyLogo({ customerId, height: 32 })}</SVGLogoWrapper>
export default memo(QRSize480)

interface IProps {
  imgPosition: 'horizontal' | 'vertical' | undefined
}
const StyledLogo = styled(Box)<IProps>`
  svg {
    height: ${(p) => (p.imgPosition === 'horizontal' ? '40px' : '80px')};
    width: 100%;
  }
`
