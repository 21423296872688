import { memo } from 'react'

import { InsideBoxWrapper } from '../../../assets/common.styled'
import { ReactComponent as IconImage } from '../../../assets/images/icons/image_code_reading_guide.svg'
import { useTranslation } from '../../../hooks/useTranslation'
import useWindowSize from '../../../hooks/useWindowSize'
import { useAppState } from '../../../overmind'
import { renderMustache } from '../../../utils/helpers'
import { convertTime } from '../../../utils/helpers.date'
import { SVGWrapper, Text, Text2, Wrapper2 } from './Delivery.styled'

const Delivery = (): JSX.Element => {
  const { translate } = useTranslation()
  const translations = translate('delivery')

  const { item } = useAppState().messages

  const { width } = useWindowSize()

  const { text1, text2, text3 } = translations

  const trackingId = item?.shipment?.trackingId || ''
  const lastDay = (item?.lock?.expiresAt && convertTime(item?.lock?.expiresAt).dateAt) || ''

  const streetAddress = item?.deliveryPoint?.address?.addressLine || ''
  const city = item?.deliveryPoint?.address?.city || ''
  const moreDetails = '(' + item?.deliveryPoint?.description + ')' || ''

  const text3Json = { lastDay }
  const lastDayText = renderMustache({ html: text3, json: text3Json })

  const text2Json = { city, moreDetails, streetAddress, trackingId }
  const trackingText = renderMustache({ html: text2, json: text2Json })

  return (
    <InsideBoxWrapper styleType="blank">
      {width < 1040 && <Text2 dangerouslySetInnerHTML={{ __html: trackingText }} />}

      <Wrapper2>
        <div>
          {width >= 1040 && <Text2 dangerouslySetInnerHTML={{ __html: trackingText }} />}
          <Text dangerouslySetInnerHTML={{ __html: text1 }} />
          <Text dangerouslySetInnerHTML={{ __html: lastDayText }} />
        </div>

        <SVGWrapper>
          <IconImage height={152} />
        </SVGWrapper>
      </Wrapper2>
    </InsideBoxWrapper>
  )
}

export default memo(Delivery)
