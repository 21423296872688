import { useEffect, useState } from 'react'

import getInfoWindowContent from './getInfoWindowContent'

interface IProps {
  infoWindow: google.maps.InfoWindow
  infoWindowContent: string
  options: google.maps.MarkerOptions
}

const Marker = ({ infoWindow, infoWindowContent, options }: IProps) => {
  const [marker, setMarker] = useState<google.maps.Marker>()

  useEffect(() => {
    if (!marker) {
      const marker = new google.maps.Marker({
        // map,
        animation: google.maps.Animation.DROP,
        title: options.title,
      })

      marker.addListener('click', () => {
        const content = infoWindowContent
        const title = options?.title || ''
        const contentString: string = getInfoWindowContent({ content, title })
        infoWindow.setContent(contentString)

        infoWindow.open({
          anchor: marker,
          // map,
          shouldFocus: true,
        })

        /*
        console.log(infowindow.getContent())
        if (infowindow.getContent()) {
          infowindow.setContent(null)
          infowindow.close()
        } else {
          const ContentString = InfoWindowCreate(options.title || '', infoWindowContent)
          infowindow.setContent(ContentString)

          infowindow.open({
            anchor: marker,
            // map,
            shouldFocus: true,
          })
        }
         */
      })
      setMarker(marker)
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  return null
}

export default Marker
