import { withStyles } from '@mui/styles'

const styles = (theme: any) => ({
  '@global': {
    '*:focus': {
      outline: 0,
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
  },
})

const globalStyles = () => null

export default withStyles(styles, { withTheme: true })(globalStyles)
