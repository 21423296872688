import styled from 'styled-components'

import { breakpoints } from '../theme/breakpoints'
import { gapMixin } from '../theme/customMixin'

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${gapMixin};

  @media screen and (min-width: ${breakpoints.xl2}) {
    flex-direction: row;
  }
`

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${gapMixin};

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;

    > div {
      flex-basis: 50%;
    }
  }

  @media screen and (min-width: ${breakpoints.xl2}) {
    flex-direction: column;

    > div {
      flex-basis: initial;
    }
  }
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 40px;
  border: 1px solid var(--white);
  border-radius: 24px;
  background-color: #f9f9f9;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 24px;

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-bottom: 0;
    padding: 48px;
  }
`
