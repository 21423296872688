import { format, parseISO } from 'date-fns'

export const convertTime = (date_at: string) => {
  try {
    const parseDateAt = parseISO(date_at)

    if (!parseDateAt) return {}

    const dateAt = format(parseDateAt, 'dd.MM.yyyy HH:mm')

    return {
      dateAt,
    }
  } catch (e) {
    return {
      dateAt: 'unknown',
    }
  }
}
