import styled from 'styled-components'

export const PhoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  svg {
    padding-right: 8px;
  }
`

export const Text = styled.div`
  color: #000;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 16px;
`

export const Text2 = styled.div`
  margin-bottom: 8px;
  color: #000;
  font-family: OpenSans-Bold, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
`

export const Text3 = styled.div`
  color: #000000;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
`

export const PhoneText = styled.div`
  color: #000;
  font-family: OpenSans-Bold, sans-serif;
  font-size: 32px;
  line-height: 28px;
  letter-spacing: 1px;
`

export const LinkText = styled.div`
  color: #6352a8;
  font-family: OpenSans-Bold, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
`

export const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`
