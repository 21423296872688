import { IError } from '../../../interfaces'

type State = {
  error: IError | null
  isLoading: boolean
  item: any | null
  kiosk: any
  shipment: any
}

export const state: State = {
  error: null,
  isLoading: true,
  item: null,
  kiosk: null,
  shipment: null,
}
