import { FC, memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Wrapper } from './assets/App.styled'
import Header from './components/Header/Header'
import Loading from './components/Loading'
import { useActions, useAppState } from './overmind'
import RoutesComponent from './route/RouteComponent'

const App: FC = (): JSX.Element => {
  const location = useLocation()

  const { appInitialized } = useAppState()
  const { setApplicationInitialized, setIsLoading } = useActions()

  const { fetchItem: messagesFetchItem } = useActions().messages

  useEffect(() => {
    ;(async () => {
      try {
        console.groupCollapsed('App ini')

        const searchParams = new URLSearchParams(location.search)
        const token: string | null = searchParams.get('codeRef')

        if (token) {
          await Promise.all([messagesFetchItem({ token })])
        } else {
          console.log('no tokens')
        }

        console.log('All content loaded')
        console.groupEnd()

        setIsLoading(false)
        setApplicationInitialized()
      } catch (e) {
        console.error(e)
        setIsLoading(false)
        setApplicationInitialized()
      }
    })()
  }, [])

  return (
    <Wrapper>
      <Header />
      {appInitialized && <RoutesComponent />}
      {!appInitialized && <Loading />}
    </Wrapper>
  )
}

export default memo(App)
