import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Marker from '../../../components/GoogleMaps/Marker'
import { useActions } from '../../../overmind'

/*
interface location {
  lat?: number
  lng?: number
}
 */

interface IProps {
  center: google.maps.LatLngLiteral
  deliveryPoints: any[] // : location[]
  onClick: (e: google.maps.MapMouseEvent) => void
  zoom: number
}

const ReceiveMapContent = ({ center, deliveryPoints, onClick, zoom }: IProps) => {
  const { initializeMap } = useActions().googleMap
  const myRef = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      if (myRef.current) {
        const map = new window.google.maps.Map(myRef.current, {
          center,
          zoom,
        })

        console.log('initialize map')
        await initializeMap({ map })
        setMap(map)
      }
    })()
  }, [])

  useEffect(() => {
    if (map) {
      ;['click'].forEach((eventName) => google.maps.event.clearListeners(map, eventName))

      if (onClick) {
        map.addListener('click', onClick)
      }
    }
  }, [map, onClick]) // onIdle

  const infoWindow = new google.maps.InfoWindow()

  return (
    <StyledMap id="map" ref={myRef}>
      {deliveryPoints &&
        deliveryPoints.map((obj: any, i: number) => {
          const title = obj.name
          const positionObject = {
            lat: obj?.location?.lat || '',
            lng: obj?.location?.lng || '',
          }
          const options = {
            map,
            position: positionObject,
            title,
          }
          if (obj) return <Marker infoWindow={infoWindow} infoWindowContent={'Content'} key={i} options={options} />
        })}
    </StyledMap>
  )
}

export default ReceiveMapContent

const StyledMap = styled.div`
  height: 400px;
`
