import { Box } from '@mui/material'
import reactElementToJSXString from 'react-element-to-jsx-string'

interface IProps {
  content: string
  title: string
}
const getInfoWindowContent = ({ content, title }: IProps): string => {
  const html = (
    <Box>
      <Box>
        <h2>{title}</h2>
      </Box>
      <Box>{content}</Box>
    </Box>
  )

  return reactElementToJSXString(html)
}

export default getInfoWindowContent
