import styled from 'styled-components'

import { breakpoints } from '../../theme/breakpoints'
import { gapMixin } from '../../theme/customMixin'

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 320px;
  border: 1px solid var(--white);
  border-radius: 24px;
  background-color: #f9f9f9;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-bottom: 0;
    padding: 48px;
  }
`

export const MapKioskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${gapMixin};

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;

    > div:first-child {
      margin-bottom: 0;
    }

    > div {
      flex-basis: 50%;
    }
  }
`

export const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.md}) {
    display: none;
  }
`
