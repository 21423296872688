import { Box } from '@mui/material'
import { memo } from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'

import { useTranslation } from '../../../../hooks/useTranslation'
import { useAppState } from '../../../../overmind'
import { getCompanyLogo } from '../../../../utils/helpers.icons'
import { PinCodeText, SVGLogoWrapper, SVGWrapper, Text, WrapperLessThen480 } from './QR.size320.styled'

const QRSize320 = (): JSX.Element => {
  const { translate } = useTranslation()
  const { pickupCode } = translate('qrCode')

  const { item } = useAppState().messages

  const codeLong = item?.lock?.codeLong
  const codeShort = item?.lock?.codeShort

  const clientObject = getCompanyLogo({ customerId: item?.customer?.customerId })

  return (
    <WrapperLessThen480>
      <SVGLogoWrapper>
        <StyledLogo imgPosition={clientObject?.position || undefined} sx={{ padding: '16px 0 0', textAlign: 'center' }}>
          {clientObject?.img || <></>}
        </StyledLogo>
      </SVGLogoWrapper>

      <SVGWrapper>
        <QRCode level="Q" size={160} value={codeLong} />
      </SVGWrapper>

      <div>
        <PinCodeText>{codeShort}</PinCodeText>
        <Text>{pickupCode}</Text>
      </div>
    </WrapperLessThen480>
  )
}

export default memo(QRSize320)

interface IProps {
  imgPosition: 'horizontal' | 'vertical' | undefined
}
const StyledLogo = styled(Box)<IProps>`
  svg {
    height: ${(p) => (p.imgPosition === 'horizontal' ? '40px' : '100px')};
    width: 100%;
  }
`
