import { memo } from 'react'

import Footer from '../../components/Footer/Footer'
import Commercial from '../../components/widgets/Commercial/Commercial'
import ServiceDesk from '../../components/widgets/ServiceDesk/ServiceDesk'
import { DashboardWrapper, WidgetWrapper } from '../common.styles'
import ReceiveMainContent from './Receive.main.content'

const ReceiveDashboard = (): JSX.Element => {
  return (
    <>
      <DashboardWrapper>
        <ReceiveMainContent />
        <WidgetWrapper>
          <Commercial />
          <ServiceDesk />
        </WidgetWrapper>
      </DashboardWrapper>
      <Footer />
    </>
  )
}

export default memo(ReceiveDashboard)
