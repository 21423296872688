import mustache from 'mustache'

interface RenderMustacheProps {
  html: string
  json: any
}
export const renderMustache = ({ html, json }: RenderMustacheProps): string => {
  try {
    return mustache.render(html, json)
  } catch (e: any) {
    console.error('Error404 renderMustache: ', e)
    return 'ERROR'
  }
}
