import { Box } from '@mui/material'
import styled from 'styled-components'

import { useTranslation } from '../../../hooks/useTranslation'
import { useAppState } from '../../../overmind'
import { renderMustache } from '../../../utils/helpers'
import { getCompanyLogo } from '../../../utils/helpers.icons'
import { Text, Wrapper } from './HasDelivered.styled'

const HasDelivered = (): JSX.Element => {
  const { translate } = useTranslation()
  const translations = translate('hasDelivered')

  const { item } = useAppState().messages
  const { text } = translations

  const trackingId = item?.shipment?.trackingId || ''

  const textJson = { trackingId }
  const trackingText = renderMustache({ html: text, json: textJson })

  const clientObject = getCompanyLogo({ customerId: item?.customer?.customerId })

  return (
    <Wrapper>
      <StyledLogo imgPosition={clientObject?.position || undefined} sx={{ padding: '16px 0 0', textAlign: 'center' }}>
        {clientObject?.img || <></>}
      </StyledLogo>

      <Text dangerouslySetInnerHTML={{ __html: trackingText }} />
    </Wrapper>
  )
}

export default HasDelivered

interface IProps {
  imgPosition: 'horizontal' | 'vertical' | undefined
}
const StyledLogo = styled(Box)<IProps>`
  svg {
    height: ${(p) => (p.imgPosition === 'horizontal' ? '40px' : '100px')};
    width: 100%;
  }
`
