import { memo } from 'react'

import { Image, Wrapper } from './Commercial.image.styled'

interface IProps {
  img: string
}

type CommercialImageFn = ({ img }: IProps) => JSX.Element

const CommercialImage: CommercialImageFn = ({ img }: IProps): JSX.Element => {
  return (
    <Wrapper>
      <Image alt="kuva" src={img} />
    </Wrapper>
  )
}

export default memo(CommercialImage)
