import { css } from 'styled-components'

import { breakpoints } from './breakpoints'

export const gapMixin = css`
  gap: 16px;

  @media screen and (min-width: ${breakpoints.sm}) {
    gap: 24px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    gap: 32px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    gap: 40px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    gap: 48px;
  }
`
