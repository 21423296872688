import { Container } from '@mui/material'
import styled from 'styled-components'

import { breakpoints } from '../theme/breakpoints'

// export const EnhancedContainer = styled.div
export const EnhancedContainer = styled(Container)`
  width: 100%;

  @media screen and (min-width: ${breakpoints.sm}) {
    min-width: 480px;
    padding: 0 40px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0 40px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 44px;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.xl1}) {
    padding: 0 108px;
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  @media screen and (min-width: ${breakpoints.sm}) {
    min-width: 480px;
    padding: 0 40px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0 40px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    padding: 0 44px;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.xl1}) {
    padding: 0 108px;
    width: 100%;
  }
`
