import { memo } from 'react'

import { ReactComponent as Logo } from '../../assets/images/logo/logo_agora_247_white.svg'
import { useTranslation } from '../../hooks/useTranslation'
import { LogoWrapper, Text, Wrapper } from './Footer.styled'

const Footer = () => {
  const { translate } = useTranslation()
  const texts = translate('footer')

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo height={40} />
      </LogoWrapper>
      <Text dangerouslySetInnerHTML={{ __html: texts.text }} />
    </Wrapper>
  )
}

export default memo(Footer)
