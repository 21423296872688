import styled from 'styled-components'

import { breakpoints } from '../../../theme/breakpoints'
import { gapMixin } from '../../../theme/customMixin'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media screen and (min-width: ${breakpoints.md}) {
    display: flex;
    margin-bottom: 24px;
  }
`

export const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  ${gapMixin};

  @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`

export const Text = styled.div`
  margin-bottom: 16px;
  color: #000;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 24px;
  }
`

export const SVGWrapper = styled.div`
  margin-bottom: 16px;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    align-items: center;
    display: flex;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 24px;
  }
`

export const Text2 = styled.div`
  margin-bottom: 16px;
  color: #000;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 24px;
  }
`
