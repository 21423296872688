import { AxiosError } from 'axios'

import { IError } from '../interfaces'

export const errorHandling = (e: any): IError => {
  const err = e as AxiosError
  let errContent: unknown = {
    code: '000',
    message: 'unknown error',
  }

  if (err.response) {
    errContent = err.response.data
  }

  return <IError>errContent
}
