import { memo } from 'react'

import { BoxWrapper } from '../../assets/common.styled'
import Delivery from '../../components/widgets/Delivery/Delivery'
import KioskInformation from '../../components/widgets/KioskInformation/Kiosk.information'
import Map from '../../components/widgets/Map/Map'
import QR from '../../components/widgets/QR/QR'
import { MapKioskWrapper } from './Receive.main.content.styled'

const ReceiveMainContent = (): JSX.Element => {
  return (
    <BoxWrapper>
      <QR />
      <Delivery />
      <MapKioskWrapper>
        <Map />
        <KioskInformation />
      </MapKioskWrapper>
    </BoxWrapper>
  )
}

export default memo(ReceiveMainContent)
