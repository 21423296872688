import { errorHandling } from '../../common'
import { Context } from '../../index'

export const resetState = ({}: Context) => {
  console.log('reset state')
}

export const fetchItem = async ({ effects, state }: Context, { token }: { token: string }): Promise<any> => {
  try {
    const result: any = await effects.messages.fetchItem({ token })
    state.messages.item = result || null
  } catch (e) {
    console.error('messages.fetchItem error: ', e)
    state.error = errorHandling(e)
    throw e
  }
}
