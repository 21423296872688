// DEFAULT VALUES
export const GOOGLE_MAPS_KEY = 'AIzaSyC3UDXcv9gv7iN63At8H5UyMbF_MFLnxM8'
export const GOOGLE_ANALYTICS_KEY = ''
export const GOOGLE_MAPS_LARGE_VIEW_URL = 'https://www.google.com/maps'

export const DEFAULT_LANGUAGE = 'fi'

// API
const API_URL = 'http://localhost:3001'
const VERSION = ''
export const BASE_API = API_URL + VERSION

export interface IError {
  code: number
  message: string
}
