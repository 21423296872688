import { Box } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'

import { BoxWrapper } from '../../../assets/common.styled'
import { ReactComponent as IconPhone } from '../../../assets/images/icons/icon_phone.svg'
import { mockServiceDeskDB } from '../../../data/mockServiceDesks'
import { useAppState } from '../../../overmind'
import { getCompanyLogo } from '../../../utils/helpers.icons'
import { LinkText, PhoneText, PhoneWrapper, SVGWrapper, Text, Text2, Text3 } from './ServiceDesk.styled'

interface IServiceDesk {
  companyId: string
  followLink: string
  followLinkText: string
  openTimes: string
  phone: string
  phoneCost: string
  title: string
}

const ServiceDesk = (): JSX.Element => {
  const serviceDeskObject: IServiceDesk = mockServiceDeskDB

  const { item } = useAppState().messages
  const clientObject = getCompanyLogo({ customerId: item?.customer?.customerId })

  return (
    <BoxWrapper>
      <SVGWrapper>
        <StyledLogo imgPosition={clientObject?.position || undefined} sx={{ padding: '16px 0 0', textAlign: 'center' }}>
          {clientObject?.img || <></>}
        </StyledLogo>
      </SVGWrapper>

      <Text2>{serviceDeskObject.title}</Text2>

      <Text>{serviceDeskObject.openTimes}</Text>

      <PhoneWrapper>
        <IconPhone height={28} />
        <PhoneText>{serviceDeskObject.phone}</PhoneText>
      </PhoneWrapper>

      <Text style={{ marginBottom: 24 }}>{serviceDeskObject.phoneCost}</Text>

      <Text3>{serviceDeskObject.followLinkText}</Text3>

      <LinkText>{serviceDeskObject.followLink}</LinkText>
    </BoxWrapper>
  )
}

export default memo(ServiceDesk)

interface IProps {
  imgPosition: 'horizontal' | 'vertical' | undefined
}
const StyledLogo = styled(Box)<IProps>`
  svg {
    height: ${(p) => (p.imgPosition === 'horizontal' ? '40px' : '100px')};
    width: 100%;
  }
`
