import LogoDevIcon from '@mui/icons-material/LogoDev'
import { find } from 'lodash'

import { clientLogos } from '../data/client.icons'

interface GetCompanyLogoProps {
  customerId?: string | null | undefined
  height?: number
}
export const getCompanyLogo = ({ customerId }: GetCompanyLogoProps): any => {
  if (!customerId)
    return {
      id: '',
      img: <LogoDevIcon sx={{ fill: 'green' }} />,
      position: 'vertical',
    }

  const clientObject = find(clientLogos, { id: customerId })

  if (clientObject) return clientObject

  return {
    id: '',
    img: <LogoDevIcon sx={{ fill: 'green' }} />,
    position: 'vertical',
  }
}
