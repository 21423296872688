import { memo } from 'react'

import { mockCommercial1 } from '../../../data/mockCommercials'
import { Content, SVGWrapper, Text, Wrapper } from './Commercial.styled'

interface ICommercial {
  image: string
  logo: string
  text: string
}

const Commercial = (): JSX.Element => {
  const commercial: ICommercial = mockCommercial1

  return (
    <Wrapper img={commercial.image}>
      <Content>
        <Text dangerouslySetInnerHTML={{ __html: commercial.text }} />
        <SVGWrapper>
          SVG
          {/**
          <Icon height={16} name={commercial.logo} />
 */}
        </SVGWrapper>
      </Content>
    </Wrapper>
  )
}

export default memo(Commercial)
