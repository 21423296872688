import Loading from '../components/Loading'
import { useAppState } from '../overmind'
import DeliveredDashboard from '../pages/Delivered/Delivered.dashboard'
import ErrorDashboard from '../pages/Error404/Error404.dashboard'
import ReceiveDashboard from '../pages/Receive/Receive.dashboard'

const RoutesComponent = (): JSX.Element => {
  const { isError, isLoading } = useAppState()
  const { item: messagesItem } = useAppState().messages

  if (isLoading) return <Loading />
  if (isError && !isLoading) return <ErrorDashboard />

  if (messagesItem?.lock?.state === 'LOCKED' && messagesItem?.lock?.extendedState === 'VALID') {
    return <ReceiveDashboard />
  } else if (messagesItem?.lock?.state === 'LOCKED' && messagesItem?.lock?.extendedState === '') {
    return <ReceiveDashboard />
  }

  if (messagesItem?.lock?.state === 'LOCKED' && messagesItem?.lock?.extendedState === 'EXPIRED') {
    return <ReceiveDashboard />
  }

  if (messagesItem?.lock?.state === 'USED' && messagesItem?.lock?.extendedState === '' && messagesItem?.shipment?.state === 'DELIVERED') {
    return <DeliveredDashboard />
  }

  return <ErrorDashboard />
}

export default RoutesComponent
