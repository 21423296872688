import styled from 'styled-components'

import { breakpoints } from '../../theme/breakpoints'
import { gapMixin } from '../../theme/customMixin'

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${gapMixin};

  @media screen and (min-width: ${breakpoints.xl2}) {
    flex-direction: row;
  }
`

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${gapMixin};

  @media screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;

    > div {
      flex-basis: 50%;
    }
  }

  @media screen and (min-width: ${breakpoints.xl2}) {
    flex-direction: column;

    > div {
      flex-basis: initial;
    }
  }
`
