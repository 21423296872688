import styled from 'styled-components'

export const Wrapper = styled.footer`
  padding: 16px;
  text-align: center;
`

export const LogoWrapper = styled.div`
  text-align: center;
  fill: var(--white);
  margin-bottom: 20px;
  opacity: 0.67;
`

export const Text = styled.div`
  opacity: 0.67;
  color: var(--white);
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
`
