import ReceiveMapContent from './ReceiveMap.content'

interface IProps {
  lat?: number
  lng?: number
}
const ReceiveMapWrapper = ({ lat = 60.54998, lng = 24.979783 }: IProps) => {
  const center = { lat, lng }
  const zoom = 7

  const handleOnClick = async (e: google.maps.MapMouseEvent) => {
    console.log('map click', e)
  }

  const deliveryPoints = [
    {
      location: { lat, lng },
    },
  ]

  return <ReceiveMapContent center={center} deliveryPoints={deliveryPoints} onClick={handleOnClick} zoom={zoom} />
}

export default ReceiveMapWrapper
