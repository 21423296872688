import { memo } from 'react'

import { ReactComponent as Logo } from '../../assets/images/logo/logo_agora_247_white.svg'
import { LogoWrapper, Text, Wrapper } from './Header.styled'

const Header = (): JSX.Element => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo height={40} />
      </LogoWrapper>

      <Text>Nouda ja lähetä pakettisi kun sinulle sopii</Text>
    </Wrapper>
  )
}

export default memo(Header)
