import { Context } from './index'

// On Intialized Overmind
export const onInitializeOvermind = async ({}: Context) => {
  console.log('onInitialize: START')
  console.log('env: ', process.env)
  console.log('onInitialize: COMPLETED')
}

export const setApplicationInitialized = ({ state }: Context) => {
  console.log('Application data loaded')
  state.appInitialized = true
}

export const setIsLoading = ({ state }: Context, isLoading: boolean) => {
  state.isLoading = isLoading
}
