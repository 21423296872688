import { memo } from 'react'

import CommercialImage from '../../components/widgets/Commercial/Commercial.image'
import HasDelivered from '../../components/widgets/HasDelivered/HasDelivered'
import { mockDeliveredCommercials } from '../../data/mockCommercials'
import { CommercialImageWrapper, Wrapper } from './Delivered.main.styled'

interface ICommercial {
  id: number
  img: string
}
const DeliveredMain = (): JSX.Element => {
  const arrCommercials: ICommercial[] = mockDeliveredCommercials

  return (
    <Wrapper>
      <HasDelivered />

      <CommercialImageWrapper>
        {arrCommercials.map((obj: ICommercial, ind: number) => {
          return <CommercialImage img={obj.img} key={ind} />
        })}
      </CommercialImageWrapper>
    </Wrapper>
  )
}

export default memo(DeliveredMain)
