import { useEffect, useState } from 'react'

import { ApplicationTranslations } from '../data/translations'
import { DEFAULT_LANGUAGE } from '../interfaces'

let currentLanguage = DEFAULT_LANGUAGE

export const useTranslation = () => {
  const [language, setLanguage] = useState(currentLanguage)
  const languages = Object.keys(ApplicationTranslations)

  useEffect(() => {
    currentLanguage = language || 'en'
  }, [language])

  const translate = (key: string) => ApplicationTranslations?.[language]?.[key]

  return { language, languages, setLanguage, translate }
}
