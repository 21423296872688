import { Context } from '../../index'

export const initializeMap = async ({ state }: Context, { map }: { map: google.maps.Map }): Promise<any> => {
  try {
    state.googleMap.map = map
  } catch (e) {
    console.error('googleMap.initializeMap error: ', e)
  }
}

export const setGeocoder = ({ state }: Context, { geocoder }: { geocoder: google.maps.Geocoder }): any => {
  try {
    state.googleMap.geocoder = geocoder
  } catch (e) {
    console.error('googleMap.setGeocoder error: ', e)
  }
}
