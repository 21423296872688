import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 32px 16px;
  border: 1px solid var(--white);
  border-radius: 24px;
  background-color: #f9f9f9;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
`

export const Text = styled.div`
  color: #000;
  font-family: OpenSans-Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
`

export const SVGWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
`
