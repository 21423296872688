import axios from 'axios'

const BASE_API = process.env.REACT_APP_BASE_API

export default {
  async fetchItem({ token }: { token: string }): Promise<any> {
    try {
      const options = {
        headers: {},
      }

      const result = await axios.get(`${BASE_API}/messages?codeRef=${token}`, options)
      return result.data
    } catch (e) {
      throw e
    }
  },
}
