import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dashed: true
  }
}

// colors
export const primary = '#77D1FF'
const secondary = '#242424'
const black = 'rgb(36, 40, 44)'
const background = '#6352A8'

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: 'none',
          color: '#000',
          fontFamily: 'Nunito-Bold',
          fontSize: '1.4em',
          height: '42px',
          minWidth: '90px',
          opacity: 0.65,
          padding: '8px 28px',
          textTransform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          background: '#FFF',
          // color: 'var(--gray-dark)',
          fontFamily: 'Nunito-Bold',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
  },
  palette: {
    background: {
      default: background,
    },
    common: {
      black,
    },
    primary: {
      main: primary,
    },
    secondary: {
      contrastText: '#ffcc00',
      light: '#0066ff',
      main: secondary,
    },
  },
  typography: {
    body1: {
      color: '#242424',
      fontSize: '1.6em',
    },

    fontFamily: ['OpenSans-Regular', 'OpenSans-SemiBold', 'OpenSans-Bold'].join(','),

    h2: {
      color: '#ffffff',
      fontFamily: 'OpenSans-Regular',
      fontSize: '4em',
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: '56px',
      opacity: 0.9,
    },
  },
})

export default theme
