import { IContext } from 'overmind'
import { merge, namespaced } from 'overmind/config'
import { createActionsHook, createStateHook } from 'overmind-react'

import * as actions from './actions'
import * as effects from './effects'
import * as googleMap from './namespaced/googleMap'
import * as messages from './namespaced/messages'
import { state } from './state'

export const configuration = merge(
  {
    actions,
    effects,
    state,
  },
  namespaced({
    googleMap,
    messages,
  }),
)

export type Context = IContext<{
  actions: typeof configuration.actions
  effects: typeof configuration.effects
  state: typeof configuration.state
}>

export const useAppState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()

type debugConf = {
  delimiter: any
  devtools: any
  hotReloading: any
  logProxies: any
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export let debugConf: debugConf = {
  delimiter: '.',
  devtools: undefined,
  hotReloading: false,
  logProxies: false,
}

if (process.env.NODE_ENV === 'development') {
  debugConf = {
    delimiter: '.',
    devtools: '127.0.0.1:3031',
    hotReloading: true,
    logProxies: true,
  }
}
