import Footer from '../../components/Footer/Footer'
import Commercial from '../../components/widgets/Commercial/Commercial'
import ServiceDesk from '../../components/widgets/ServiceDesk/ServiceDesk'
import { DashboardWrapper, WidgetWrapper } from './Delivered.dashboard.styled'
import DeliveredMain from './Delivered.main'

const DeliveredDashboard = (): JSX.Element => {
  return (
    <>
      <DashboardWrapper>
        <DeliveredMain />
        <WidgetWrapper>
          <Commercial />
          <ServiceDesk />
        </WidgetWrapper>
      </DashboardWrapper>
      <Footer />
    </>
  )
}

export default DeliveredDashboard
