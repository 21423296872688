import './assets/styles.scss'

import DateFnsUtils from '@date-io/date-fns'
import { Status, Wrapper as WrapperGoogleMap } from '@googlemaps/react-wrapper'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import locale from 'date-fns/locale/fi'
import GA4React from 'ga-4-react'
import { createOvermind } from 'overmind'
import { Provider } from 'overmind-react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import { configuration, debugConf } from './overmind'
import reportWebVitals from './reportWebVitals'
import GlobalStyles from './theme/GlobalStyles'
import theme from './theme/theme'

let ga4react: any
if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY)

const overmind = createOvermind(configuration, debugConf)

const container: any = document.getElementById('root')
const root = ReactDOM.createRoot(container)

const renderMap = (status: Status): JSX.Element => {
  if (status === Status.LOADING) return <div>SPINNER</div>
  if (status === Status.FAILURE) return <div>ERROR</div>
  return <></>
}

window.addEventListener('DOMContentLoaded', () => {
  overmind.initialized
    .then(async () => {
      try {
        if (!window.location.href.includes('localhost')) {
          if (ga4react) await ga4react.initialize()
        }

        root.render(
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyles />
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale} utils={DateFnsUtils}>
                <Provider value={overmind}>
                  <Router>
                    <WrapperGoogleMap apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY || ''} render={renderMap}>
                      <App />
                    </WrapperGoogleMap>
                  </Router>
                </Provider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>,
        )
      } catch (e) {
        console.log('Failed to start application')
      }
      return
    })
    .catch((e) => {
      console.error('overmind initialized failed ', e)
    })
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
