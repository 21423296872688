import Image1 from '../assets/images/image/image_bike_large.jpg'
import Image4 from '../assets/images/image/image_elixir.jpg'
import Image2 from '../assets/images/image/image_shoe_large.jpg'
import Image3 from '../assets/images/image/image_watches_large.jpg'

export const mockCommercial1 = {
  image: 'images/agora-package-consumer-1024x682.jpeg',
  logo: 'images/logo/logo_db_schenker_white',
  text: 'Seuraava lähetyksesi <b>-50%</b> alennuskoodilla <b>spring4u</b>',
}

export const mockDeliveredCommercials = [
  { id: 0, img: Image1 },
  { id: 1, img: Image2 },
  { id: 2, img: Image3 },
  { id: 3, img: Image4 },
]
