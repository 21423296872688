// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
type StringKeyType = {
  [index: string]: any
}

export const ApplicationTranslations: StringKeyType = {
  en: {
    delivery: {
      text1: 'Noutaaksesi lähetyksen <b>syötä noutokoodi</b> kioskiin tai <b>näytä QR-koodi</b> kioskin lukijalle (ks. oheinen kuvaohje).',
      text2: 'Lähetyksesi <b>{{trackingId}}</b> on noudettavissa pakettikioskista osoitteesta <b>{{streetAddress}}, {{city}}</b> {{moreDetails}}.',
      text3: 'Lähetyksen viimeinen noutopäivä on <b>{{lastDay}}.</b>',
    },
    footer: {
      text: '© 2021 Agora Networks Oy.<br />All rights reserved.',
    },
    hasDelivered: {
      text: 'Lähetyksesi <b>{{trackingId}}</b> on noudettu pakettikioskista.',
    },
    kioskInformation: {
      moreInfo: 'Lue lisää',
      text: '<b>Agora 24/7</b> -pakettikioski on aina auki oleva älykäs automaatti josta noudat lähetyksesi ja johon jätät palautuksesi noudettaviksi kun sinulle sopii. ',
    },
    map: {
      bigMap: 'Näytä isompana',
      direction: 'Reittiohje',
      text: 'Näytä Ajo-ohje',
    },
    name: 'Agora 24/7',
    qrCode: {
      notify: '<b>Agora 24/7</b> -pakettikioski on auki joka päivä ympäri vuorokauden.',
      pickupCode: 'Noutokoodi',
      text: 'Lähetyksesi <b>{{trackingId}}</b> on noudettavissa pakettikioskista osoitteesta <b>{{streetAddress}}, {{city}}</b> {{moreDetails}}.',
    },
  },
  fi: {
    delivery: {
      text1: 'Noutaaksesi lähetyksen <b>syötä noutokoodi</b> kioskiin tai <b>näytä QR-koodi</b> kioskin lukijalle (ks. oheinen kuvaohje).',
      text2: 'Lähetyksesi <b>{{trackingId}}</b> on noudettavissa pakettikioskista osoitteesta <b>{{streetAddress}}, {{city}}</b> {{moreDetails}}.',
      text3: 'Lähetyksen viimeinen noutopäivä on <b>{{lastDay}}.</b>',
    },
    footer: {
      text: '© 2021 Agora Networks Oy.<br />All rights reserved.',
    },
    hasDelivered: {
      text: 'Lähetyksesi <b>{{trackingId}}</b> on noudettu pakettikioskista.',
    },
    kioskInformation: {
      moreInfo: 'Lue lisää',
      text: '<b>Agora 24/7</b> -pakettikioski on aina auki oleva älykäs automaatti josta noudat lähetyksesi ja johon jätät palautuksesi noudettaviksi kun sinulle sopii. ',
    },
    map: {
      bigMap: 'Näytä isompana',
      direction: 'Reittiohje',
      text: 'Näytä Ajo-ohje',
    },
    name: 'Agora 24/7',
    qrCode: {
      notify: '<b>Agora 24/7</b> -pakettikioski on auki joka päivä ympäri vuorokauden.',
      pickupCode: 'Noutokoodi',
    },
  },
}
