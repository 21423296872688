import { memo } from 'react'

import { InsideBoxWrapper } from '../../../assets/common.styled'
import useWindowSize from '../../../hooks/useWindowSize'
import QRSize320 from './Size320/QR.size320'
import QRSize480 from './Size480/QR.size480'

const QR = (): JSX.Element => {
  const { width } = useWindowSize()

  return (
    <>
      <InsideBoxWrapper styleType="blank">
        {width < 480 && <QRSize320 />}
        {width >= 480 && <QRSize480 />}
      </InsideBoxWrapper>
    </>
  )
}

export default memo(QR)
