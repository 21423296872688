import { derived } from 'overmind'

import { IError } from '../interfaces'

type State = {
  appInitialized: boolean
  error: IError | null
  isError: any
  isLoading: boolean
}

export const state: State = {
  appInitialized: false,
  error: null,
  isError: derived(({ error }: State) => !!error),
  isLoading: true,
}
