import styled from 'styled-components'

import { breakpoints } from '../../theme/breakpoints'

export const Container = styled.div`
  display: flex;

  > div {
    flex-basis: 50%;
  }
`
export const LeftSide = styled.div`
  text-align: center;
`
export const RightSide = styled.div``

export const Header = styled.div`
  height: 68px;
  color: #656565;
  font-family: OpenSans-Bold, sans-serif;
  font-size: 50px;
  letter-spacing: 0.83px;
  line-height: 68px;

  @media screen and (min-width: ${breakpoints.sm}) {
  }

  @media screen and (min-width: ${breakpoints.md}) {
    height: 82px;
    font-size: 60px;
    letter-spacing: 1px;
    line-height: 82px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    height: 96px;
    font-size: 70px;
    letter-spacing: 1.17px;
    line-height: 96px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    height: 103px;
    font-size: 80px;
    letter-spacing: 1.33px;
    line-height: 109px;
  }

  @media screen and (min-width: ${breakpoints.xl1}) {
    height: 136px;
    font-size: 100px;
    letter-spacing: 1.67px;
    line-height: 136px;
  }

  @media screen and (min-width: ${breakpoints.xl2}) {
    height: 160px;
    font-size: 120px;
    letter-spacing: 2px;
    line-height: 163px;
  }
`

export const Description = styled.div`
  color: var(--font-black);
  font-family: OpenSans-Bold, sans-serif;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 22px;

  @media screen and (min-width: ${breakpoints.sm}) {
    color: #484848;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 27px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    font-size: 24px;
    letter-spacing: 0.24px;
    line-height: 33px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    font-size: 28px;
    letter-spacing: 0.28px;
    line-height: 38px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    font-size: 32px;
    letter-spacing: 0.32px;
    line-height: 43px;
  }

  @media screen and (min-width: ${breakpoints.xl1}) {
    font-size: 36px;
    letter-spacing: 0.36px;
    line-height: 49px;
  }

  @media screen and (min-width: ${breakpoints.xl2}) {
  }
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 40px;
  border: 1px solid var(--white);
  border-radius: 24px;
  background-color: #f9f9f9;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 24px;

  @media screen and (min-width: ${breakpoints.xl}) {
    margin-bottom: 0;
    padding: 48px;
  }
`
