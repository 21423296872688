import styled from 'styled-components'

import { breakpoints } from '../../../theme/breakpoints'

export const Text = styled.div`
  color: var(--font-black);
  font-family: OpenSans-Regular, sans-serif;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 24px;
`

export const LinkText = styled.div`
  color: var(--purple-light);
  font-family: OpenSans-Bold, sans-serif;
  font-size: 1.6em;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
`

export const SVGWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;

  img {
    width: 100%;
    max-height: 300px;

    @media screen and (min-width: ${breakpoints.md}) {
      width: auto;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
      max-height: 200px;
    }
  }
`
