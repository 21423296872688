import { memo } from 'react'

import { InsideBoxWrapper } from '../../../assets/common.styled'
import { useAppState } from '../../../overmind'
import ReceiveMapWrapper from '../../../pages/Receive/map/ReceiveMap.wrapper'

const Map = (): JSX.Element => {
  const { item } = useAppState().messages

  const lat = item?.deliveryPoint?.location?.lat || ''
  const lng = item?.deliveryPoint?.location?.lng || ''

  return (
    <InsideBoxWrapper>
      <ReceiveMapWrapper lat={lat} lng={lng} />
    </InsideBoxWrapper>
  )
}

export default memo(Map)
