import { memo } from 'react'

import { InsideBoxWrapper } from '../../../assets/common.styled'
import KIOSK_IMAGE from '../../../assets/images/image/image_kioskwithuser_bitmap_large.png'
import { useTranslation } from '../../../hooks/useTranslation'
import { LinkText, SVGWrapper, Text } from './Kiosk.information.styled'

const KioskInformation = (): JSX.Element => {
  const { translate } = useTranslation()
  const translations = translate('kioskInformation')

  const { moreInfo, text } = translations

  return (
    <InsideBoxWrapper styleType="content">
      <Text dangerouslySetInnerHTML={{ __html: text }} />

      <SVGWrapper>
        <img alt="Kiosk" src={KIOSK_IMAGE} />
      </SVGWrapper>

      <LinkText dangerouslySetInnerHTML={{ __html: moreInfo }} />
    </InsideBoxWrapper>
  )
}

export default memo(KioskInformation)
