import styled from 'styled-components'

import { breakpoints } from '../../../theme/breakpoints'

interface IWrapper {
  img: string
}
export const Wrapper = styled.div<IWrapper>`
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  min-height: 235px;
  // margin-bottom: 24px;
  border-radius: 24px;
  background-color: #f9f9f9;
  background-image: url(${(p) => p.img});
  background-size: cover;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.24);

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  border-radius: 0 0 24px 24px;
  padding: 24px 16px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
`

export const Text = styled.div`
  color: var(--white);
  font-family: OpenSans-Regular, sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: center;
`

export const SVGWrapper = styled.div`
  padding-top: 12px;
`
