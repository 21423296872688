import styled from 'styled-components'

import { breakpoints } from '../../../theme/breakpoints'

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  background-color: var(--white);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  padding: 24px 16px;

  @media screen and (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`

export const Image = styled.img`
  text-align: center;
  width: 100%;
  border-radius: 24px;
`
