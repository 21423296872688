import styled from 'styled-components'

import { breakpoints } from '../theme/breakpoints'

export const BoxWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 24px;
  background-color: var(--widget-background);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;

  @media screen and (min-width: ${breakpoints.sm}) {
    border-radius: 32px;
    padding: 24px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 32px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    border-radius: 40px;
    padding: 40px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    border-radius: 48px;
    padding: 48px;
  }
`

const insideBoxWrapperStyles = (styleType: string | undefined) => {
  switch (styleType) {
    case 'content': {
      return {
        backgroundColor: '#f3f5f9',
      }
    }
    case 'blank': {
      return {
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
      }
    }
    default: {
      return {
        backgroundColor: '#ffffff',
        padding: 0,
      }
    }
  }
}

interface InsideBoxWrapperProps {
  styleType?: string | undefined
}

export const InsideBoxWrapper = styled.div<InsideBoxWrapperProps>`
  box-sizing: border-box;
  border: 1px solid #dfdbed;
  border-radius: 12px;
  // margin-bottom: 24px;
  padding: 16px;

  @media screen and (min-width: ${breakpoints.sm}) {
    //  margin: 0 24px 24px;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    //  margin: 0 32px 32px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    // margin-bottom: 32px;
    //  margin: 0 40px 40px;
  }

  @media screen and (min-width: ${breakpoints.xl}) {
    //  margin: 0 48px 48px;
  }

  ${(p) => insideBoxWrapperStyles(p.styleType)}
`
