import styled from 'styled-components'

import { breakpoints } from '../../theme/breakpoints'

export const Wrapper = styled.header`
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`

export const LogoWrapper = styled.div`
  text-align: center;
  fill: var(--white);
  margin-bottom: 8px;
`

/*
export const SVGWrapper = styled.div`
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    text-align: left;
    line-height: normal;
    display: flex;
    align-items: center;
  }
`
 */

export const Text = styled.div`
  color: var(--white);
  font-family: OpenSans-Regular, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-left: 32px;
    text-align: left;
    line-height: normal;
  }
`
