import { ReactComponent as LogoDB } from '../assets/images/logo/logo_col_db_schenker.svg'
import { ReactComponent as LogoDHL } from '../assets/images/logo/logo_col_dhl.svg'
import { ReactComponent as LogoPorvoo } from '../assets/images/logo/logo_porex.svg'

export const clientLogos = [
  {
    id: 'pagf56zGA5eJvZSdO1J9',
    img: <LogoPorvoo />,
    position: 'vertical',
  },
  {
    id: '3zTsSt5Ghdy57vTNTMkG',
    img: <LogoDB />,
    position: 'horizontal',
  },
  {
    id: 'yKLvebuPGDLESRrVa5sv',
    img: <LogoDHL />,
    position: 'horizontal',
  },
]
